
import { Component, Vue, Prop } from 'vue-property-decorator'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import ProductTimeSelect from '../ProductTimeSelect/ProductTimeSelect.vue'
import { ProductCategoryViewData } from '@/services/ProductService/ProductCategoryViewData'
import { ProductViewData } from '@/services/ProductService/ProductViewData'
import { GETTERS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import LinkToPartouOffers from '../LinkToPartouOffers/LinkToPartouOffers.vue'
import { ServiceVarietyName } from '@/models'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import ProductCategoryFeature from '@/models/ProductCategoryFeature'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouNotify, ProductTimeSelect, LinkToPartouOffers }
})
export default class ProductCategorySelect extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedProductIds)
  getSelectedProductIds!: () => string[]

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedServiceVarieties)
  getSelectedServiceVarieties!: () => string[]

  @Prop({ required: true, default: () => [] })
  productCategories!: ProductCategoryViewData[]

  @Prop({ required: false, default: true })
  showPricing!: boolean

  @Prop({ required: false, default: false })
  showLinkToPartouOffers!: boolean

  @Prop({ required: false, default: false })
  displayVSOProducts!: boolean

  serviceVarietiesInProposition: ServiceVarietyName[] = []
  showFeatureInfo = false
  featureTitle = ''
  featureDescription = ''
  selectedCategory: string | undefined = ''
  uniqueCategories: (string | undefined)[] = []
  selectableProducts: ProductViewData[] = []

  mounted () {
    this.serviceVarietiesInProposition = this.userSelectionFormState.selectedServiceVarieties
    const ids = this.getSelectedProductIds()
    const category = this.productCategories.find(cat =>
      cat.products.some(product => ids.indexOf(product.id) !== -1 && product.serviceVariety !== ServiceVarietyName.VSO)
    )

    if (category) {
      this.selectedCategory = category.name
      this.selectableProducts = category.products
    } else {
      this.selectedCategory = this.productCategories[0].name
      this.selectableProducts = this.productCategories[0].products
    }
  }

  showFeature (feature: ProductCategoryFeature):boolean {
    const featureIsForSelectedServiceVariety = feature.availableForServiceVarieties !== undefined && feature.availableForServiceVarieties.some((x: ServiceVarietyName) => this.serviceVarietiesInProposition.includes(x))
    return featureIsForSelectedServiceVariety
  }

  isCheapestCategory (index: number): boolean {
    const cheapestPrice = this.getHourRate(index)
    return this.productCategories.every((cat, i) =>
      i === index || this.getHourRate(i) >= cheapestPrice
    )
  }

  formatHourRate (hourRate : number) : string {
    return `€ ${(hourRate / 100).toFixed(2).replace('.', ',')}`
  }

  async onFeatureInfoCancelled () : Promise<void> {
    this.showFeatureInfo = false
    await new Promise(resolve => setTimeout(resolve, 200))
    this.featureTitle = ''
    this.featureDescription = ''
  }

  onFeatureInfoClicked (title : string, description: string) : void {
    this.featureTitle = title
    this.featureDescription = description
    this.showFeatureInfo = true
  }

  onCategorySelected (index : number) {
    this.selectedCategory = this.productCategories[index].name
    this.selectableProducts = this.productCategories[index].products.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
  }

  getHourRate (index : number): number {
    const selectedServiceVarieties = this.getSelectedServiceVarieties()

    let serviceVariety = ServiceVarietyName.KDV
    if (selectedServiceVarieties.includes(ServiceVarietyName.NSO)) {
      serviceVariety = ServiceVarietyName.NSO
    }

    const productsOfServiceVariety = this.productCategories[index].products.filter(x => x.serviceVariety === serviceVariety)
    return productsOfServiceVariety[0].pricePerHour
  }
}
