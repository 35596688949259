import { Feature, GetDefaultFeaturesDocument, GetDefaultFeaturesQuery, GetOfferByIdDocument, GetOfferByIdQueryVariables, GetServicesBySchoolIdDocument, GetServicesBySchoolIdQueryVariables, GetOfferBySlugDocument, GetOfferBySlugQueryVariables, GetLocationLocalitiesByServiceVarietyNamesDocument, GetServiceNamesByLocalityDocument, GetServiceNamesByLocalityQueryVariables, GetOffersByGeoLocationAndSchoolDocument, GetOffersByGeoLocationAndSchoolQueryVariables, GetOffersByGeoLocationDocument, GetOffersByGeoLocationQueryVariables, Offer, OfferDistance, QueryRoot, Service, GetServiceByIdQueryVariables, GetServiceByIdDocument, Location, GetServiceSlugByMdmidAndServiceKindQueryVariables, GetServiceSlugByMdmidAndServiceKindDocument } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import IOfferService, { GetServicesBySchoolIdVariables } from '@/services/OfferService/IOfferService'

@injectable()
export default class OfferService implements IOfferService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  public async getServicesBySchoolIdAsync (variables: GetServicesBySchoolIdVariables): Promise<Service[]> {
    const queryVariables : GetServicesBySchoolIdQueryVariables = {
      schoolId: variables.schoolId,
      serviceVarietyNames: variables.serviceVarietyNames
    }

    const result = await this.apollo.query<QueryRoot>({
      query: GetServicesBySchoolIdDocument,
      variables: queryVariables
    })

    if (variables.filterForChildBenefitCalculator) {
      return result.data.service.filter(x => x.serviceSettings[0].availableInCalculator)
    }

    return result.data.service
  }

  public async getLocationLocalitiesByServiceVarietyNamesAsync (serviceVarietyNames : string[]): Promise<string[]> {
    const result = await this.apollo.query<QueryRoot>({
      query: GetLocationLocalitiesByServiceVarietyNamesDocument,
      variables: { serviceVarietyNames }
    })
    return (result.data.location as Partial<Location>[]).map(x => x.locality ?? '')
  }

  public async getServiceNamesByLocalityAsync (variables : GetServiceNamesByLocalityQueryVariables): Promise<Service[]> {
    const result = await this.apollo.query<QueryRoot>({
      query: GetServiceNamesByLocalityDocument,
      variables
    })
    return result.data.service as Service[]
  }

  public async getServiceByIdAsync (variables: GetServiceByIdQueryVariables): Promise<Service> {
    variables.dateOfOpeningTime = variables.dateOfOpeningTime ?? new Date()

    const result = await this.apollo.query<QueryRoot>({
      query: GetServiceByIdDocument,
      variables
    })
    return result.data.service[0]
  }

  public async getServiceSlugByMdmidAndServiceKind (variables: GetServiceSlugByMdmidAndServiceKindQueryVariables): Promise<string> {
    const result = await this.apollo.query<QueryRoot>({
      query: GetServiceSlugByMdmidAndServiceKindDocument,
      variables
    })
    return result.data.service[0]?.slug ?? ''
  }

  public async getOfferByIdAsync (variables: GetOfferByIdQueryVariables): Promise<Offer> {
    variables.dateOfOpeningTime = variables.dateOfOpeningTime ?? new Date()

    const result = await this.apollo.query<QueryRoot>({
      query: GetOfferByIdDocument,
      variables
    })
    return result.data.offer[0]
  }

  public async getOfferBySlugAsync (variables: GetOfferBySlugQueryVariables): Promise<Offer> {
    variables.dateOfOpeningTime = variables.dateOfOpeningTime ?? new Date()

    const result = await this.apollo.query<QueryRoot>({
      query: GetOfferBySlugDocument,
      variables
    })
    return result.data.offer[0]
  }

  public async getOffersByGeoLocationAsync (variables: GetOffersByGeoLocationQueryVariables | GetOffersByGeoLocationAndSchoolQueryVariables): Promise<OfferDistance[]> {
    const bySchool = !!(variables as GetOffersByGeoLocationAndSchoolQueryVariables).schoolId

    const result = await this.apollo.query<QueryRoot>({
      query: bySchool ? GetOffersByGeoLocationAndSchoolDocument : GetOffersByGeoLocationDocument,
      variables
    })

    return result.data.offer_by_distance
  }

  public async getDefaultFeatures () : Promise<Feature[]> {
    const result = await this.apollo.query<GetDefaultFeaturesQuery>({
      query: GetDefaultFeaturesDocument
    })
    return result.data.feature as Feature[]
  }
}
