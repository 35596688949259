
import ChildBenefitCalculator from '@/components/ChildBenefitCalculator/ChildBenefitCalculator.vue'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouBottomSheet from '@/components/PartouComponents/PartouBottomSheet/PartouBottomSheet.vue'
import PartouCarousel from '@/components/PartouComponents/PartouCarousel.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import AvailabilitySelector from '@/components/AvailabilitySelector/AvailabilitySelector.vue'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { Feature, GetOfferBySlugQueryVariables, Proposition, Service, ServiceKind, ServiceVarietyName } from '@/models'
import { getServiceKindParamFromServiceKind, ROUTES } from '@/router/routes'
import { GETTERS, NAMESPACES, STATE, ACTIONS } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import ContactContent from './Content/ContactContent.vue'
import OpeningTimesContent from './Content/OpeningTimesContent.vue'
import LocationContent from './Content/LocationContent.vue'
import IFeatureService from '@/services/FeatureService/IFeatureService'
import MultiStateDaySelectorLegend from '@/components/MultiStateDaySelector/MultiStateDaySelectorLegend.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import PropositionSubscription from '@/models/PropositionSubscription'
import Debounce from '@/utils/decorators/debounceDecorator'
import Page from '@/pages/Page'
import { Dictionary } from 'lodash'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import PartouIconButton from '@/components/PartouComponents/Buttons/PartouIconButton.vue'
import SharingMenu from '@/components/SharingMenu/SharingMenu.vue'
import { sendBookTourAnalyticsEvent, sendMoreInformationAnalyticsEvent, sendDiscoverOtherLocationsAnalyticsEvent, sendReserveRegistrationAnalyticsEvent, sendMdmIdEvent, sendBookExternalAnalyticsEvent, sendLocationPageLandingAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import UnprocessablePropositionSubscriptionError from '@/services/PropositionService/UnprocessablePropositionSubscriptionError'
import DateValidationPopups from '@/components/AvailabilitySelector/DateValidationPopups.vue'
import Availability from '@/models/enums/Availability'
import PartouIconText from '@/components/PartouComponents/PartouIconText.vue'
import { determineTypeOfCare } from '@/models/enums/ServiceKind'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const propositionModule = namespace(NAMESPACES.proposition)
const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: {
    OpeningTimesContent,
    ContactContent,
    PageHeader,
    PartouCarousel,
    PartouFloatingCircleButton,
    PartouButton,
    PartouIconButton,
    PartouBottomSheet,
    ChildBenefitCalculator,
    LocationContent,
    MultiStateDaySelectorLegend,
    PartouNotify,
    PartouCard,
    PartouLoader,
    AvailabilitySelector,
    PartouSocials,
    SharingMenu,
    DateValidationPopups,
    PartouIconText
  },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'description', content: (this as any).metaDescription }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'index, follow' }
      ]
    }
  }
})
export default class ServiceDetailPage extends Vue implements Page {
  pageTitle = ''
  metaDescription = ''

  @Ref('background-shape')
  backGroundShapeElement! : HTMLElement

  @Ref('description')
  descriptionElement! : HTMLElement

  @Ref('header-content')
  headerContentElement! : HTMLElement

  @propositionModule.Action(ACTIONS.proposition.getPropositionsAsync)
  getPropositionsAsync!: () => Promise<void>

  @propositionModule.Getter(GETTERS.proposition.getPropositionBySlug)
  getPropositionBySlug!: (serviceSlug: string) => Proposition | undefined

  @propositionModule.Getter(GETTERS.proposition.getPropositionById)
  getPropositionById!: (id: string) => Proposition | undefined

  @propositionModule.Action(ACTIONS.proposition.getPropositionSubscriptionsAsync)
  getPropositionSubscriptionsAsync!: (offer: { offerId: string, productIds?: string[] }) => Promise<void>

  @propositionModule.Action(ACTIONS.proposition.getPropositionBySlugAsync)
  getPropositionBySlugAsync! : (payload: GetOfferBySlugQueryVariables) => Promise<void>

  @propositionModule.State(STATE.proposition.propositions)
  propositions?: Array<Proposition>

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState! : IUserSelectionFormState

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedPropositionId)
  setSelectedPropositionId!: (setSelectedPropositionId: string) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setPostalCodeAsync)
  setPostalCodeAsync!: ({ postcode, longitude, latitude } : { postcode: string, longitude : number, latitude : number }) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceVarietiesAsync)
  setSelectedServiceVarietiesAsync!: (selectedServiceVarieties : ServiceVarietyName[]) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceAsync)
  setSelectedServiceAsync!: (selectedService: Service) => Promise<void>

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any>  /* Disabled because of any */// eslint-disable-line

  featureService! : IFeatureService

  @Prop({ required: true })
  serviceSlug!: string

  features: Array<Feature> = []

  showIncorrectAgeErrorMessage = false
  showDaySelector = false
  showLegend = false
  showMoreExpanded = false
  showPraticalInfo = false
  showSmallTourButton = false
  backgroundShapeHeight = 454

  isLoadingPrice = false
  isLoadingPropositions = false

  proposition?: Proposition | null = null

  get ServiceVarieties () : string | undefined {
    if (this.proposition?.serviceVarieties) {
      return this.proposition.serviceVarieties.flatMap((serviceVariety) => serviceVariety.name).join(', ')
    }
  }

  get showMoreInfoButton () : boolean {
    return this.proposition?.service.mdmId && this.configuration.externalUrls?.locationPage
  }

  get showBookTourButton () : boolean {
    return this.proposition?.service.mdmId && this.configuration.externalUrls?.tourPage
  }

  get isBookableInSelectionGuide () : boolean {
    return this.proposition?.availability !== Availability.NotBookable
  }

  get openingTimesNote () : string {
    const serviceSettings = this.proposition?.service.serviceSettings
    return (serviceSettings && serviceSettings.length && serviceSettings[0].openingTimesNote) || ''
  }

  async beforeCreate () : Promise<void> {
    // Note: @inject does not work for some reason, so this is a workaround
    this.featureService = container.get(SERVICE_IDENTIFIERS.IFeatureService)
  }

  async created () : Promise<void> {
    await this.reloadPropositionsAsync()
  }

  async mounted () : Promise<void> {
    await this.getPropositionBySlugAsync({ slug: this.serviceSlug, dateOfOpeningTime: this.userSelectionFormState.startDateOfDayCare })
    await this.getPropositionOrOffer()
    if (this.proposition) {
      await this.setSelectedServiceAsync(this.proposition.service)
      const serviceVarietyName = this.proposition.serviceVarieties.some(x => x.name.toUpperCase() === ServiceVarietyName.KDV)
        ? this.$t('KDV')
        : this.$t('BSO')
      this.pageTitle = this.$t('pageTitles.locationDetail', { serviceVarietyName, name: this.proposition.name }).toString()
      sendLocationPageLandingAnalyticsEvent(this.proposition)
      sendMdmIdEvent(this.proposition)
    }
  }

  async getPropositionOrOffer () : Promise<void> {
    if (!this.proposition || this.proposition.slug !== this.serviceSlug) {
      this.proposition = this.getPropositionBySlug(this.serviceSlug)
      if (this.proposition) {
        this.setSelectedPropositionId(this.proposition.id)
      } else {
        this.$router.replace({ name: ROUTES.notFound })
      }
    }
  }

  @Watch('propositions', { immediate: true, deep: true })
  onPropositionsChanged () : void {
    if (this.userSelectionFormState.selectedPropositionId) {
      this.proposition = this.getPropositionById(this.userSelectionFormState.selectedPropositionId)
    }
  }

  @Watch('proposition', { immediate: true })
  async onPropositionChanged (newValue: Proposition | null, oldValue: Proposition | null) : Promise<void> {
    if (newValue && newValue.id !== oldValue?.id) {
      if (newValue.postalCode && newValue.postalCode !== this.userSelectionFormState.postalCode?.postcode) {
        this.setPostalCodeAsync({ postcode: newValue.postalCode.replace(' ', ''), longitude: newValue.longitude ?? 0, latitude: newValue.latitude ?? 0 })
      }

      await this.reloadPropositionsAsync()
      await this.getSubscriptionsForPropositionAsync()
      await this.getFeaturesAsync()
    }
  }

  getDefaultSubscription () : PropositionSubscription | undefined {
    return this.proposition?.propositionSubscriptions?.[0]
  }

  getAllPropositionSubscriptions () : PropositionSubscription[] {
    const serviceSettings = this.proposition?.service?.serviceSettings?.[0] ?? undefined
    return serviceSettings?.useFlexkidsProducts ? this.proposition?.propositionSubscriptions ?? [] : []
  }

  async onDaySelectorChangedAsync () : Promise<void> {
    // Set isLoadingPropositions directly to prevent blinking in AvailabilitySelector
    this.isLoadingPropositions = true
    await this.reloadPropositionsAsync()
  }

  async onShowAvailabilityClicked () : Promise<void> {
    this.showSmallTourButton = false
    await this.reloadPropositionsAsync()
  }

  async emitOnShowSchoolsClicked () : Promise<void> {
    this.showSmallTourButton = false
  }

  @Debounce(600)
  async reloadPropositionsAsync () : Promise<void> {
    this.isLoadingPropositions = true
    await this.getPropositionsAsync()
    this.getSubscriptionsForPropositionAsync()
    this.isLoadingPropositions = false
  }

  async getSubscriptionsForPropositionAsync () :Promise<void> {
    if (this.proposition && this.proposition.id) {
      this.isLoadingPrice = true
      try {
        await this.getPropositionSubscriptionsAsync({ offerId: this.proposition.id })
      } catch (e) {
        if (!(e instanceof UnprocessablePropositionSubscriptionError)) {
          throw e
        }
      }
      this.isLoadingPrice = false
    }
  }

  async getFeaturesAsync () : Promise<void> {
    const nrFeaturesToShow = 5
    const currentFeatures = this.proposition?.serviceFeatures?.map(x => x.feature).slice(0, nrFeaturesToShow) ?? []
    const defaultFeatures = [...await this.featureService.getDefaultFeaturesAsync() ?? []]
    while (currentFeatures.length < nrFeaturesToShow && defaultFeatures.length > 0) {
      const randomFeatureIndex = Math.floor(Math.random() * ((defaultFeatures.length - 1) + 1))
      const randomFeature = defaultFeatures.splice(randomFeatureIndex, 1)[0]
      if (!currentFeatures.find(x => x.description === randomFeature.description)) {
        currentFeatures.push(randomFeature)
      }
    }
    this.features = currentFeatures
  }

  onBackButtonClicked () : void {
    this.onLocationInNeighbourhoodClickedAsync()
  }

  onResetAvailabilitySelectorClicked () : void {
    this.showSmallTourButton = true
  }

  onIncorrectAgeEntered () : void {
    this.showIncorrectAgeErrorMessage = true
  }

  onMoreInfoClicked () : void {
    if (this.configuration.externalUrls.locationPage && this.proposition && this.proposition.service.mdmId) {
      sendMoreInformationAnalyticsEvent(this.proposition)
      this.$router.redirectToPartouSite(ROUTES.external.getLocationPage(), this.proposition.kind as ServiceKind, this.proposition.service.mdmId)
    }
  }

  onBookTourClicked () : void {
    if (this.configuration.externalUrls.tourPage && this.proposition && this.proposition.service.mdmId) {
      sendBookTourAnalyticsEvent(this.proposition)
      this.$router.redirectToPartouSite(ROUTES.external.getTourPageUrl(), this.proposition.kind as ServiceKind, this.proposition.service.mdmId)
    }
  }

  onBookExternalClicked () : void {
    if (this.configuration?.externalUrls?.bookPage && this.proposition && this.proposition.service.mdmId) {
      const redirectToUrl = this.$router.buildRedirectToPartouSiteUrl(ROUTES.external.getBookPageUrl(), this.proposition.kind as ServiceKind, this.proposition.service.mdmId)
      const currentUrl = window.location.href
      sendBookExternalAnalyticsEvent(currentUrl, redirectToUrl, this.proposition)
      this.$router.redirectToPartouSite(ROUTES.external.getBookPageUrl(), this.proposition.kind as ServiceKind, this.proposition.service.mdmId)
    }
  }

  get locationsLink () : string {
    return ROUTES.locationsWithPostalCode
  }

  get locationsLinkParams () : Dictionary<string> | undefined {
    if (this.proposition) {
      const serviceKind = this.proposition.kind as ServiceKind

      return {
        postalCode: this.userSelectionFormState.postalCode?.postcode ?? '',
        serviceKind: getServiceKindParamFromServiceKind(serviceKind)
      }
    }
  }

  async onLocationInNeighbourhoodClickedAsync () : Promise<void> {
    const locationsLinkParams = this.locationsLinkParams
    if (locationsLinkParams && this.userSelectionFormState.dateOfBirth && this.userSelectionFormState.startDateOfDayCare) {
      locationsLinkParams.serviceKind = determineTypeOfCare(this.userSelectionFormState.dateOfBirth, this.userSelectionFormState.startDateOfDayCare) === ServiceKind.SchoolCare ? getServiceKindParamFromServiceKind(ServiceKind.SchoolCare) : getServiceKindParamFromServiceKind(ServiceKind.DayCare)
    } else if (locationsLinkParams && this.proposition) {
      locationsLinkParams.serviceKind = this.proposition.kind === ServiceKind.DayCare ? getServiceKindParamFromServiceKind(ServiceKind.DayCare) : getServiceKindParamFromServiceKind(ServiceKind.SchoolCare)
    }

    if (this.proposition) {
      sendDiscoverOtherLocationsAnalyticsEvent(this.proposition.service.kind)
    }
    this.$router.push({
      name: this.locationsLink,
      params: locationsLinkParams
    })
  }

  onShowOfferButtonClicked () : void {
    if (this.proposition) {
      sendReserveRegistrationAnalyticsEvent(this.proposition)
    }
    this.$router.push({ name: ROUTES.orderOverview, params: { serviceSlug: this.serviceSlug } })
  }

  toggleDaySelector () : void {
    this.showDaySelector = true
  }

  toggleShowMore () : void {
    this.showMoreExpanded = !this.showMoreExpanded
  }

  onInfoClicked () : void {
    this.showLegend = true
  }
}
