import { inject, injectable } from 'inversify'
import IPostalCodeService from './IPostalCodeService'
import ApolloClient, { ApolloError } from 'apollo-client'
import { FetchPostalcodeInfoDocument, FetchPostalcodeInfoQueryVariables, QueryRoot } from '@/models'
import IOperationContext from '../HasuraService/OperationContext'
import { StatusCodes } from 'http-status-codes'
import HttpResponseError from '@/Errors/HttpResponseError'
import PostalCodeServiceError from './PostalCodeServiceErrors'
import { GetPostalCodeResponse } from './PostalcodeServiceResponses/GetPostalCodeInfoResponse'
import { Maybe } from 'graphql/jsutils/Maybe'
import PostalCodeInfo from '@/models/PostalCodeInfo'
import { regexes } from '@/definitions'

@injectable()
export default class PostalCodeService implements IPostalCodeService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  isPostalCodeFormatValid (postalCode: string): boolean {
    const dutchValidationRegex = regexes.validation.postalcodeRegex
    const regex = new RegExp(dutchValidationRegex)
    const isValid = regex.test(postalCode)
    return isValid
  }

  async getPostalCodeInfo (variables: FetchPostalcodeInfoQueryVariables): Promise<GetPostalCodeResponse> {
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.UNPROCESSABLE_ENTITY]
    }

    try {
      const result = await this.apollo.query<QueryRoot>({
        query: FetchPostalcodeInfoDocument,
        variables,
        context
      })

      const response: Maybe<PostalCodeInfo> = result?.data?.fetchPostalcodeInfo?.postalCodeInfo
      if (!response || !result?.data?.fetchPostalcodeInfo?.isSuccess) {
        return new GetPostalCodeResponse(null, PostalCodeServiceError.notFound)
      }

      return new GetPostalCodeResponse(response)
    } catch (e) {
      if (!(e instanceof ApolloError) || !(e.networkError instanceof HttpResponseError)) {
        throw e
      }

      const statusCode = e.networkError.statusCode

      if (!context.expectedErrorCodes.includes(statusCode)) {
        throw e
      }

      if (e.networkError.message === 'POSTAL_CODE_IS_POST_OFFICEBOX') {
        return new GetPostalCodeResponse(null, PostalCodeServiceError.isPostOfficeBox)
      }

      if (e.networkError.message === 'POSTAL_CODES_SERVICE_TEMPORARILY_UNAVAILABLE') {
        return new GetPostalCodeResponse(null, PostalCodeServiceError.serviceUnavailable)
      }

      throw e
    }
  }
}
