
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { SelectableSubscription } from './SelectableSubscription'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import CustomSubscription from './CustomSubscription/CustomSubscription.vue'
import { ServiceVarietyName } from '@/models'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import SubscriptionFeature from '@/models/SubscriptionFeature'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouNotify, CustomSubscription }
})
export default class SubscriptionSelect extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @Prop({ required: true, default: () => [] })
  selectableSubscriptions!: SelectableSubscription[]

  @Prop({ required: false, default: true })
  showPricing!: boolean

  serviceVarietiesInProposition: ServiceVarietyName[] = []
  showFeatureInfo = false
  featureTitle = ''
  featureDescription = ''

  mounted (): void {
    this.serviceVarietiesInProposition = this.userSelectionFormState.selectedServiceVarieties
  }

  get selectedSubscriptionNote () : string | undefined {
    const subscriptionService = this.selectableSubscriptions.find(x => x.isSelected)?.subscriptionService

    if (subscriptionService?.isDisplayNote && subscriptionService.note) {
      return subscriptionService.note
    }

    return undefined
  }

  get selectedSubscriptionIsCustomEnabled () : boolean {
    const subscriptionService = this.selectableSubscriptions.find(x => x.isSelected)?.subscriptionService

    return subscriptionService?.service?.isCustomSubscriptionEnabled ?? false
  }

  showFeature (feature: SubscriptionFeature):boolean {
    const featureIsForSelectedServiceVariety = feature.availableForServiceVarieties !== undefined && feature.availableForServiceVarieties.some((x: ServiceVarietyName) => this.serviceVarietiesInProposition.includes(x))
    return featureIsForSelectedServiceVariety
  }

  isCheapestSubscription (subscriptionId : string) : boolean {
    const cheapestSubscription = this.selectableSubscriptions.reduceRight((prev, curr) => prev.propositionSubscription.pricePerHour < curr.propositionSubscription.pricePerHour ? prev : curr)
    return cheapestSubscription?.id === subscriptionId ?? false
  }

  formatHourRate (hourRate : number) : string {
    return `€ ${hourRate.toFixed(2).replace('.', ',')}`
  }

  async onFeatureInfoCancelled () : Promise<void> {
    this.showFeatureInfo = false
    await new Promise(resolve => setTimeout(resolve, 200))
    this.featureTitle = ''
    this.featureDescription = ''
  }

  onFeatureInfoClicked (title : string, description: string) : void {
    this.featureTitle = title
    this.featureDescription = description
    this.showFeatureInfo = true
  }

  @Emit('onSubscriptionSelected')
  onSubscriptionSelected (index : number) : SelectableSubscription {
    this.selectableSubscriptions.forEach(x => { x.isSelected = false })
    this.selectableSubscriptions[index].isSelected = true
    return this.selectableSubscriptions[index]
  }
}
